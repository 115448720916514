import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';

import { FilterService } from '@proman/services/filter.service';
import * as echarts from 'echarts';
import { EChartsOption } from 'echarts';
import { isDefined } from '@proman/utils';

export interface SankeyChartData {
    data: Array<{ name: string, depth?: number; }>;
    links: Array<{
        source: string;
        target: string;
        value?: number
    }>;
}

export interface SankeyChartConfig {
    max: number;
    groups: Array<{ name: string; state?: boolean }>;
    handleClick?: (name: string, event: MouseEvent) => void;
    handleNavigation?: (name: string, event: MouseEvent) => void;
}

@Component({
    selector: 'pro-echart-sankey',
    template: `
      <div class="EchartSankey" fxLayout="column">
        <div fxLayout="row"
              class="SidePadding-16">
          <div *ngFor="let group of config.groups" fxFlex class="SankeyTitleContainer">
            <ng-container *ngIf="group.state">
              <h3>
                <a href="" proClickStopPropagation
                   (click)="handleGroupNavigation(group.name, $event)"
                >
                  {{ group.name | translate }}
                </a>
              </h3>
            </ng-container>
            <ng-container *ngIf="!group.state">
              <h3>
               {{ group.name | translate }}
              </h3>
            </ng-container>
          </div>
        </div>
        <div class="EchartStack RightMargin" id="sankey"
             [style.height]="height + 'px'"
             fxFlex #element (window:resize)="onResize()">

        </div>
      </div>
    `,
  styles: [`
    .SankeyTitleContainer {
      padding-left: 60px;
    }
    .SankeyTitleContainer h3 {
      margin-bottom: 0;
    }
  `]
})

export class EchartSankeyComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() data: SankeyChartData;
    @Input() config: SankeyChartConfig;
    @ViewChild('element', { static: true }) element: ElementRef;

    chart: any;
    height: number = 400;

    constructor(
        private Filter: FilterService,
    ) {

    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        // this.onResize();
    }

    onResize() {
        echarts.init(this.element.nativeElement).resize();
        this.setHeight();
        this.draw();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (!(this.data)) return;

        this.setHeight();

        await setTimeout(() => {}, 500);


        this.draw();
    }

    draw() {
        // console.log('draw', this);
        if (!this.data.data.length) return;

        let myChart = echarts.init(document.getElementById('sankey'));

        const links = [...this.data.links.map(this.getLinkWeight)];

        let option: EChartsOption = {
            series: {
                type: 'sankey',
                // layout: 'none',
                emphasis: {
                    focus: 'adjacency'
                },
                nodeAlign: 'left',
                data: this.uniqNodes(this.data.data),
                links,
            }
        };
        // use configuration item and data specified to show chart
        myChart.setOption(option);
      myChart.on('click', (params) => {
        console.log(params);
        if (this.config.handleClick) {
          this.config.handleClick(params.data['name'] as string, params.event.event as unknown as MouseEvent);
        }
      });
    }

    getLinkWeight = (item: {
        source: string;
        target: string;
        value?: number
    }) => {
        if (isDefined(item.value)) return item;

        let value = 1;

        return { ...item, value: value || 1 };

    };

    getItemWeight(name: string, type: 'source'|'target'): number {
        return this.data.links.filter((item) => item[type] === name).length;
    }

    setHeight() {
        this.height = Math.max(this.config.max * 15, 400);
    }

    uniqNodes = (data: Array<{ name: string }>) => {
        // does not work, relation target/source fails
        data.forEach((item1, i) => {
            data.forEach((item2,j ) => {
               if (item1.name === item2.name && i !== j) {
                   // item1.name = `${item1.name}_1`;
                   // item2.name = `${item2.name}_2`;
                   console.log('same name', item1.name,i, j);
               }
            });
        });

        return [...data];
    };

    handleGroupNavigation(name: string, event: MouseEvent) {
      this.config?.handleNavigation(name, event);
    }

}

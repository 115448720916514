import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import * as _echarts from 'echarts';
import { FilterService } from '@proman/services/filter.service';
import { EchartOrdinalConfig, EchartOrdinalData } from '@proman/interfaces/object-interfaces';
import {delay, isMobile, isObject} from '@proman/utils';
const echarts: any = _echarts;

const DEFAULT_COLORS = ['#95B227', '#77B0FF', '#b28b27', '#ff7777'];

@Component({
    selector: 'pro-echart-ordinal',
    template: `
        <div fxLayout="column">
            <div class="EchartOrdinal RightMargin" id="bar" (proOnResize)="onResize()" fxLayoutAlign="center center" #element style="height: 400px;"></div>
            <div fxLayout="row" fxLayoutAlign="center center" style="padding: 0 32px;">
                <span *ngIf="(configOld && !configOld.hideSumData) || (config && !config.hideSumData)"> {{ sumData }}</span>
            </div>
        </div>
    `
})

export class EchartOrdinalComponent implements OnInit, OnChanges {
    @Input() dataOld: any;
    @Input() configOld: {
        colors: string[];
        left: { label: string };
        middle?: {label: string};
        right: { label: string };
        hideSumData?: boolean;
    };
    @Input() data: EchartOrdinalData[];
    @Input() config: EchartOrdinalConfig;

    @ViewChild('element', { static: true }) element: ElementRef;
    sumLeft: string;
    sumRight: string;
    height: string = '350px';
    myChart: any;

    sumData: string = '';

    constructor(private Filter: FilterService) {}

    async onResize() {
        if (isMobile()) {
            await delay(500);
        }

        this.element.nativeElement.height = this.height;
        this.element.nativeElement.width = window.innerWidth;
        this.myChart = echarts.init(this.element.nativeElement).resize();

        this.draw();
    }

    ngOnInit() {
        this.draw();

        if (isMobile()) {
            setTimeout(() => this.onResize(), 300);
        }
    }

    ngOnChanges() {
        this.draw();
    }

    draw = async () => {
        await setTimeout(() => {}, 500);

        if (this.dataOld && this.configOld) {
            return this.drawOld();
        } else if (this.data && this.config) {
            return this.drawNew();
        }

    };

    drawOld = () => {
        this.element.nativeElement.height = this.height;
        this.myChart = echarts.init(this.element.nativeElement);
        let xAxisValues = this.dataOld.map((item: any) => item.value);

        const sum = (items: number[]) => {
            return items.reduce((a, b) => a + b, 0);
        };

        this.sumLeft = sum(this.dataOld.map((item: any) => item.leftValue)).toFixed(0);
        this.sumRight = sum(this.dataOld.map((item: any) => item.rightValue)).toFixed(0);

        this.sumData = `${this.sumLeft} | ${this.sumRight}`

        let label;

        if ((window.innerWidth / this.dataOld.length) > 150) {
            label = {
                normal: {
                    show: this.dataOld.length < 20,
                    position: 'inside'
                }
            };
        }

        let serie1 = {
            name: this.Filter.translate(this.configOld.left.label),
            type: 'bar',
            animation: true,
            animationDuration: 2000,
            areaStyle: {
            },
            lineStyle: {
                width: 1
            },
            barGap: 0,
            data: this.dataOld.map((item: any) => item.leftValue),
            label,
            itemStyle: { normal: { color: this.configOld.colors[0] } },
        };

        let serie2 = {
            name: this.Filter.translate(this.configOld.right.label),
            type: 'bar',
            yAxisIndex: 1,
            animation: true,
            animationDuration: 2000,
            areaStyle: {
            },
            lineStyle: {
                width: 1
            },
            data: this.dataOld.map((item: any) => item.rightValue),
            label,
            itemStyle: { normal: { color: this.configOld.colors[1] } },

        };
        let serie3 = {
            name: this.Filter.translate(this.configOld.middle?.label),
            type: 'bar',
            yAxisIndex: 2,
            animation: true,
            animationDuration: 2000,
            areaStyle: {
            },
            lineStyle: {
                width: 1
            },
            data: this.dataOld.map((item: any) => item.middleValue),
            label,
            itemStyle: { normal: { color: this.configOld.colors.length>2 ? this.configOld.colors[2] : this.configOld.colors[0]} },

        };


        const option = {
            grid: {
                left: '32px',
                right: !this.configOld.middle ? '24px' : '148px',
                bottom: '32px',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: { title: 'Save as image' }
                }
            },
            tooltip : {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    label: {
                        backgroundColor: '#505765'
                    }
                }
            },
            legend: {
                data: [this.Filter.translate(this.configOld.left.label), this.Filter.translate(this.configOld.right.label)],
                orient: 'horizontal',
                top: 'bottom'
            },
            xAxis : [
                {
                    type : 'category',
                    boundaryGap : 4,
                    axisLine: { onZero: false },
                    data : xAxisValues
                }
            ],
            yAxis: [
                {
                    name: this.Filter.translate(this.configOld.left.label),
                    type: 'value',
                    axisLabel: {
                        rotate: 30
                    },
                    nameTextStyle: {
                        align: 'left'
                    },
                    min: 0,
                },
              {
                name: this.Filter.translate(this.configOld.right.label),
                type: 'value',
                axisLabel: {
                  rotate: 30
                },
                  nameTextStyle: {
                      align: 'right'
                  },
                min: 0,
              },

            ],
            series: [
                serie1,
                serie2,
            ]
        };

        if(this.configOld.middle) {
          (option.yAxis as any[]).push(
            {
              name: this.Filter.translate(this.configOld.middle?.label),
              type: 'value',
              axisLabel: {
                rotate: 30
              },
              min: 0,
              offset: 130,
            }
          )
          option.series.push(serie3)
          option.legend.data.push(
              this.Filter.translate(this.configOld.middle.label),
          )
        }

        this.myChart.setOption(option);
    };

    drawNew = () => {
        this.element.nativeElement.height = this.height;
        this.myChart = echarts.init(this.element.nativeElement);
        let xAxisValues = this.data.map((item) => item.xValue);

        let label;

        if ((window.innerWidth / this.data.length) > 150) {
            label = {
                normal: {
                    show: this.data.length < 20,
                    position: 'inside'
                }
            };
        }


        const series = this.config.labels.map((label: any, i) => ({
            name: this.Filter.translate(isObject(label) ? (label as any).name : label),
            label: label,
            type: 'bar',
            animation: true,
            animationDuration: 2000,
            areaStyle: {
            },
            lineStyle: {
                width: 1
            },
            yAxisIndex: isObject(label) ? (label.hideAxis ? null : i) : i,
            barGap: 0,
            data: this.data.map((item) => item.values[i]),
            itemStyle: { normal: { color: (this.config.colors||DEFAULT_COLORS)[i] } },
        }));

        const option = {
            grid: {
                left: '32px',
                right: !this.config.yAxis?.hideLabel && this.config.labels.length > 2 ? `${this.config.labels.length * 24}px` : '48px',
                bottom: '32px',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: { title: 'Save as image' }
                }
            },
            tooltip : {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    label: {
                        backgroundColor: '#505765'
                    }
                }
            },
            legend: {
                data: this.config.labels.map((label) => this.Filter.translate(isObject(label) ? (label as any).name : label)),
                orient: 'horizontal',
                top: 'bottom'
            },
            xAxis : [
                {
                    type : 'category',
                    boundaryGap : 4,
                    axisLine: { onZero: false },
                    data : xAxisValues
                }
            ],
            yAxis: this.config.labels.map((label, i) => ({
                name: !this.config.yAxis?.hideLabel ? this.Filter.translate(isObject(label) ? (label.hideAxis ? null : label.name) : label) : null,
                nameRotate: this.config.yAxis?.yAxisLabelStyle ? 30 : 0,
                type: 'value',
                axisLabel: {
                    show: !this.config.yAxis?.hideLabel,
                    rotate: 30
                },
                min: 0,
                offset: this.config.yAxis?.hideLabel ? 0 : i < 2 ? 0 : ((i - 1) * 50),
                nameTextStyle: this.config.yAxis?.yAxisLabelStyle ? { fontWeight: 'bold' } : null
            })),
            series
        };

        // this.sumLeft = sum(this.dataOld.map((item) => item.leftValue)).toFixed(0);
        // this.sumRight = sum(this.dataOld.map((item) => item.rightValue)).toFixed(0);

        const sum = (items: number[]) => {
            return items.reduce((a, b) => a + b, 0);
        };

        this.sumData = this.config.labels.map((l , i) => {
            // const sumData = sum(this.data.values.map((val) => val[i]));
            return sum(this.data.map((item) => +item.values[i]));
        }).join(' | ')

        if (this.config.xAxis) Object.assign(option.xAxis[0], this.config.xAxis);
        this.myChart.setOption(option);
    };
}

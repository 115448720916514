import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import moment from 'moment';
import { importD3 } from '@proman/utils';

let d3: any = window.d3;
@Component({
    selector: 'pro-time-graph',
    template: `
        <div class="Chart"
             #d3element>
        </div>
    `
})

export class TimeGraphComponent implements OnInit, OnChanges {
    @Input() date: any;
    @Input() data: any;
    @Input() timeStamp: any;
    @Input() tooltipFunc: any = (d: any) => '';
    @ViewChild('d3element', { static: true }) element: ElementRef;

    svg: any;
    colorName: string[] = ['green', 'blue', 'darkorange', 'red', 'violet'];
    x: any;
    y: any;
    xAxis: any;
    yAxis: any;
    svgRoot: any;
    gradRoot: any;
    margin: any;
    width: any;
    height: any;
    pane: any;
    zoom: any;

    types: any;

    constructor() {}

    ngOnInit() {
        importD3()
            .then(() => {
                d3 = window.d3;

                if (!d3) {
                    console.warn('d3 not loaded');
                }
            }).then(() => {

            let element: any = this.element.nativeElement;
            this.svgRoot = d3.select(element).append('svg');
            this.gradRoot = d3.select(element).append('svg').style('width', 1).style('height', 1);
            this.x = d3.time.scale();
            this.y = d3.scale.ordinal();
            this.yAxis = d3.svg.axis();
            this.xAxis = d3.svg.axis();
            this.zoom = d3.behavior.zoom();

            setTimeout(this.init, 150); // timeout to render fxFlex
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.draw();
    }

    updateTime = () => {
        this.date.from = moment(this.x.domain()[0]).format();
        this.date.to = moment(this.x.domain()[1]).format();
    };

    gradFill(col1: any, col2: any, url: any) {
        let grad: any = this.gradRoot.append('defs').append('linearGradient')
            .attr('id', url)
            .attr('x1', '0%')
            .attr('y2', '100%')
            .attr('x2', '100%')
            .attr('y1', '0%')
            .attr('spreadMethod', 'pad');

        grad.append('stop')
            .attr('offset', '0%')
            .attr('stop-color', col1)
            .attr('stop-opacity', 1);

        grad.append('stop')
            .attr('offset', '100%')
            .attr('stop-color', col2)
            .attr('stop-opacity', 1);
    }

    index = (level: any, url: any, title: any) => {
        let svg = this.svg;
        svg.append('g')
            .append('circle')
            .attr('transform', 'translate(' + 0 + ',' + 0 + ')')
            .attr('cx', 20 + 130 * (level - 1))
            .attr('cy', 20)
            .attr('r', 8)
            .attr('class', 'barColor')
            .attr('fill', 'url(' + window.location.href + '#' + url + ')');

        svg.append('g')
            .append('text')
            .attr('transform', 'translate(' + 0 + ',' + 0 + ')')
            .attr('x', 20 + 130 * (level - 1) + 15)
            .attr('y', 20 + 4)
            .text(title);
    };

    prepareCanvas() {
        let element = this.element.nativeElement;
        let margin;
        let width = element.offsetWidth - 32;
        let height = element.offsetHeight;
        let colorName: any = this.colorName;

        this.gradRoot.selectAll('*').remove();

        if (this.svg) this.svg.selectAll('*').remove();

        this.margin = { right: 10, left: 140, top: 60, bottom: 60 };
        margin = this.margin;

        this.width = width - margin.right - margin.left > 200 ? width - margin.right - margin.left : 200;
        this.height = height / 2 - margin.top - margin.bottom > 150 ? height / 2 - margin.top - margin.bottom : 150;

        this.svg = this.svgRoot
            .attr('width', width + margin.right + margin.left)
            .attr('height', height + margin.bottom + margin.top);

        this.data.forEach((dataItem: any, i: number) => {
            this.gradFill(colorName[i], d3.hsl(colorName[i]).darker(), colorName[i]); // gradient fill colors SVG definitions
            this.index(i + 1, colorName[i], dataItem.label); // agenda index of values of graph
        });

    }

    prepareAxis() {

        let height = this.height;
        let width = this.width;
        let margin = this.margin;
        let y = this.y;
        let xAxis = this.xAxis;
        let yAxis = this.yAxis;
        let svg = this.svg;
        let yAxisValues = this.data.map((dataItem: any) => dataItem.label).reverse();

        this.x.range([0, width]);

        y.domain([''].concat(yAxisValues))
            .range([height, height / 4 * 3, height / 2, height / 4, 0]);

        yAxis.scale(y)
            .orient('left')
            .tickSize(-width, 5)
            .tickPadding(6);

        xAxis.scale(this.x)
            .orient('top')
            .tickSize(-height, 5)
            .tickPadding(6);

        svg.append('g')
            .attr('class', 'x axis')
            .attr('transform', 'translate(' + margin.left + ',' + (margin.top) + ')');

        svg.append('g')
            .attr('class', 'y axis')
            .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
            .call(yAxis);

    }

    draw() {

        if (!this.data || !this.x) return;

        this.x.domain([new Date(this.date.from), new Date(this.date.to)]);

        this.zoome();
    }

    graphDraw(data: any, gradName: any, title: any) {
        let x = this.x;
        let height = this.height;
        let svg = this.svg;
        let to = moment(x.domain()[1]);
        let from = moment(x.domain()[0]);
        let left = this.margin.left;
        let top = this.margin.top;

        svg.append('g')
            .attr('class', 'timeChar')
            .selectAll('rect')
            .data(data).enter().append('rect')
            .attr('transform', 'translate(' + left + ',' + top + ')')
            .attr('x', (d: any) => {
                if (to <= d.start || from >= d.end) {
                    return null;
                }
                if (from >= d.start) {
                    return x(from) + 1;
                }
                return x(d.start);
            })
            .attr('y', this.y(title) - 10 - height / 60)
            .attr('height', 20 + height / 30)
            .attr('width', (d: any) => {
                if (to <= d.start || from >= d.end) {
                    return null;
                }
                if (from >= d.start && to <= d.end) {
                    return x(to) - x(from);
                }
                if (from >= d.start) {
                    return x(d.end) - x(from);
                }
                if (from <= d.start && to <= d.end) {
                    return x(to) - x(d.start);
                }
                return x(d.end) - x(d.start);
            })
            .attr('ry', 2)
            .attr('rx', 2)
            .style('fill', 'url(' + window.location.href + '#' + gradName + ')')
            .append('title')
            .text((d: any) => title + this.tooltipFunc(d));

    }

    zoome = () => {

        if (!this.data || !this.svg) return;

        this.svg.selectAll('g.timeChar').remove();
        this.svg.select('g.x.axis').call(this.xAxis);

        this.data.forEach((dataItem: any, index: number) => {
            this.graphDraw(dataItem.data, this.colorName[index], dataItem.label);
        });
    };

    init = () => {
        this.prepareCanvas();
        this.prepareAxis();
        this.draw();

        setInterval(this.updateTime, 5000);
    };

}

import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FilterService } from '@proman/services/filter.service';
import moment from 'moment';
import { importD3 } from '@proman/utils';

let d3: any = window.d3;
@Component({
    selector: 'pro-graph-ordinal',
    template: `
<!--        <div class="GraphOrdinal" fxFlex #element>-->
<!--            <div class="chart-holder" style="width: 100%"></div>-->
<!--            <div class="chart-legend" style="width: 100%"></div>-->
<!--        </div>-->
    `
})

export class GraphOrdinalComponent implements OnInit, OnChanges {
    @Input() data: any;
    @Input() config: any;
    @ViewChild('element', { static: true }) element: ElementRef;
    init: boolean = true;
    barOffset: any = 0;
    margin: any = {
        right: 55,
        left: 55,
        top: 30,
        bottom: 30
    };

    constructor(
        private Filter: FilterService,
    ) {

    }

    /* eslint-disable @typescript-eslint/no-this-alias */

    ngOnInit() {
        importD3()
            .then(() => {
                d3 = window.d3;

                if (!d3) {
                    console.warn('d3 not loaded');
                }
            });
    }

    ngOnChanges() {
        if (this.data) setTimeout(() => this.draw(), 150);

    }

    draw = () => {
        const app: any = this;
        const element: any = app.element.nativeElement;
        app.height = (app.config.height ? app.config.height : 300) - app.margin.top - app.margin.bottom;

        d3.format = d3.locale({
            'decimal': ',',
            'thousands': ' ',
            'grouping': [3],
            'currency': ['€', ''],
            'dateTime': '%a %b %e %X %Y',
            'date': '%Y-%m-%d',
            'time': '%H:%M:%S',
            'periods': ['AM', 'PM'],
            'days': app.Filter.translate('_day_names').split(' '),
            'shortDays': app.Filter.translate('_day_names_short').split(' '),
            'months': app.Filter.translate('_month_names').split(' '),
            'shortMonths': app.Filter.translate('_month_names_min').split(' ')
        }).numberFormat;

        function xDataFormat(item: any) {
            let value;
            switch (app.config.xFormat) {
                case 'time' :
                    value = d3.time.format.iso.parse(item);
                    break;
                case 'text' :
                    value = item;
                    break;
                default :
                    value = item;
            }
            return value;
        }

        function nodata(state: boolean) {
            app.svg = d3.select(element);

            app.svg.selectAll('.mousearea, .graph, .y1, .y2, .leftavg, .rightavg, .legend, .leftlabel, .rightlabel')
                .style('display', (state ? 'none' : 'block'));

            app.svg.select('.nodata')
                .style('display', (!state ? 'none' : 'block'));
        }

        function draw() {
            app.width = parseInt(d3.select(element).select('.chart-holder').style('width')) - app.margin.left - app.margin.right;

            app.svg = d3.select(element).select('.chart-holder').append('svg')
                .attr('width', app.width + app.margin.left + app.margin.right)
                .attr('height', app.height + app.margin.top + app.margin.bottom)
                .append('g')
                .attr('transform', 'translate(' + app.margin.left + ',' + app.margin.top + ')');

            switch (app.config.xFormat) {

                case 'time' : {
                    app.x = d3.time.scale().range([0, app.width]);

                    break;
                }

                case 'text' : {
                    app.x = d3.scale.ordinal().rangeBands([0, app.width]);

                    break;
                }

                default : {
                    app.x = d3.scale.linear().range([0, app.width]);
                }

            }

            app.y1 = d3.scale.linear().range([app.height, 0]);

            app.y2 = d3.scale.linear().range([app.height, 0]);

            app.xAxis = d3.svg.axis()
                .scale(app.x)
                .orient('bottom');

            if (typeof app.config.xFormat !== 'undefined' && app.config.xFormat === 'time') {
                app.xAxis.tickFormat(app.d3TimeLocalization.getTickFormat());
            }

            if (typeof app.config.xFormat !== 'undefined' && app.config.xFormat === 'text') {
                app.xAxis.tickFormat(function(d: any) {
                    return d.substr(0, Math.round(app.barWidth / 2));
                });
            }

            app.y1Axis = d3.svg.axis()
                .scale(app.y1)
                .orient('left');

            app.y2Axis = d3.svg.axis()
                .scale(app.y2)
                .orient('right');

            app.legend = d3.select(element).select('.chart-legend')
                .append('div')
                .attr('class', 'legend')
                .attr('width', app.width + app.margin.left + app.margin.right)
                .style('padding', '10px')
                .style('text-align', 'center');

            app.svg.append('text')
                .attr('class', 'nodata')
                .style('display', 'none')
                .attr('x', app.width / 2)
                .attr('y', app.height / 2)
                .attr('text-anchor', 'middle')
                .text(app.Filter.translate('data_unavailable'));

            app.svg.append('text')
                .style('text-anchor', 'end')
                .attr('class', 'leftlabel')
                .attr('transform', 'translate(12,0)rotate(-90)')
                .text(app.Filter.translate(app.config.left.label));

            app.svg.append('text')
                .style('text-anchor', 'end')
                .attr('class', 'rightlabel')
                .attr('transform', 'translate(' + (app.width - 6) + ',0)rotate(-90)')
                .text(app.Filter.translate(app.config.right.label));

            app.svg.append('g')
                .attr('class', 'axis y1')
                .call(app.y1Axis);

            app.svg.append('g')
                .attr('class', 'axis y2')
                .attr('transform', 'translate(' + app.width + ',0)')
                .call(app.y2Axis);

            app.svg.append('g')
                .attr('class', 'axis x')
                .attr('transform', 'translate(0,' + app.height + ')')
                .call(app.xAxis)
                .selectAll('text')
                .style('text-anchor', 'end')
                .attr('transform', function() {
                    return 'translate(' + -10 + ',' + 10 + ')rotate(-90)';
                });

            app.svg.append('line')
                .attr('class', 'leftavg')
                .style('stroke', app.config.colors[0])
                .style('stroke-width:', 1)
                .attr('x1', 0)
                .attr('x2', app.width);

            app.svg.append('line')
                .attr('class', 'rightavg')
                .style('stroke', app.config.colors[1])
                .style('stroke-width:', 1)
                .attr('x1', 0)
                .attr('x2', app.width);

            app.barsContainer = app.svg.append('g')
                .attr('class', 'bars');

            app.barValue = app.svg
                .append('g')
                .attr('visibility', 'hidden');

            app.barValue.append('line')
                .style('stroke', 'red')
                .attr('x1', 0);

            app.barValue.append('text')
                .attr('y', -5)
                .attr('text-anchor', 'middle')
                .attr('alignment-baseline', 'baseline');
        }

        function update() {

            let rect;
            let group;
            let leftBar;
            let rightBar;

            let maxleft = d3.max(app.data, function(value: any) {
                return value.leftValue * 1.1;
            });

            let maxright = d3.max(app.data, function(value: any) {
                return value.rightValue * 1.1;
            });

            hideBarValue();

            if (maxleft === 0) {
                // all values are 0
                // if max is zero, all values on x are full height
                maxleft = 1;
            }
            if (maxright === 0) {
                // all values are 0
                // if max is zero, all values on x are full height
                maxright = 1;
            }

            switch (app.config.xFormat) {
                case 'time' : {
                    app.x.domain([d3.time.day.offset(xDataFormat(app.config.from), -1), d3.time.day.offset(xDataFormat(app.config.to), 0)]);
                    app.barWidth = app.x(d3.time.day.offset(xDataFormat(app.config.from), 0)) / 3;
                    break;
                }
                case 'text' : {
                    app.x.domain(app.data.map(function(value: any) {
                        return value.value;
                    }));
                    app.barWidth = app.x.rangeBand() / 4;
                    app.barOffset = app.x.rangeBand() / 2;
                    break;
                }
                default : {
                    app.x.domain(app.data.map(function(value: any) {
                        return value.value;
                    }));
                    app.barWidth = 10;
                }
            }

            app.y1.domain([0, maxleft]);

            app.y2.domain([0, maxright]);

            app.svg.select('.x.axis')
                .transition()
                .call(app.xAxis);

            app.svg.select('.y1.axis')
                .transition()
                .call(app.y1Axis);

            app.svg.select('.y2.axis')
                .transition()
                .call(app.y2Axis);

            app.barsContainer.selectAll('g.bars').remove();

            rect = app.barsContainer.selectAll('g.bars').data(app.data);

            group = rect.enter()
                .append('g')
                .attr('class', 'bars');

            leftBar = group.append('rect')
                .attr('fill', app.config.colors[0])
                .style('shape-rendering', 'crispEdges')
                .attr('width', app.barWidth)
                .attr('class', 'graphl')
                .attr('x', function(d: any) {
                    return app.x(xDataFormat(d.value)) - app.barWidth + app.barOffset;
                })
                .on('mouseover', function(d: any) {
                    showBarValue(((d.value.match(/^[0-9]{2}/) && moment(d.value).isValid() ? moment(d.value).format('YYYY-MM-DD') : d.value) + ': ' + app.Filter.translate(app.config.left.label) + ' - ' + d.leftValue), app.x(xDataFormat(d.value)), app.y1(d.leftValue), - app.barWidth);
                })
                .on('mouseout', function() {
                    hideBarValue();
                })
                .attr('y', function() {
                    return app.height;
                })
                .attr('height', function() {
                    return app.y1(maxleft);
                });

            rightBar = group.append('rect')
                .attr('fill', app.config.colors[1])
                .style('shape-rendering', 'crispEdges')
                .attr('width', app.barWidth)
                .attr('class', 'graphr')
                .attr('x', function(d: any) {
                    return app.x(xDataFormat(d.value)) + app.barOffset;
                })
                .on('mouseover', function(d: any) {
                    showBarValue(((d.value.match(/^[0-9]{2}/) && moment(d.value).isValid() ? moment(d.value).format('YYYY-MM-DD') : d.value) + ': ' + app.Filter.translate(app.config.right.label) + ' - ' + d.rightValue), app.x(xDataFormat(d.value)), app.y2(d.rightValue), app.barWidth);
                })
                .on('mouseout', function() {
                    hideBarValue();
                })
                .attr('y', function() {
                    return app.height;
                })
                .attr('height', function() {
                    return app.y2(maxright);
                });

            if (typeof app.config.callback === 'function') {
                leftBar
                    .on('click', function(d: any) {
                        app.config.callback(d);
                    })
                    .style('cursor', 'pointer');

                rightBar
                    .on('click', function(d: any) {
                        app.config.callback(d);
                    })
                    .style('cursor', 'pointer');
            }

            leftBar.transition()
                .attr('y', function(value: any) {
                    return app.height - app.y1(maxleft - value.leftValue);
                })
                .attr('height', function(value: any) {
                    return app.y1(maxleft - value.leftValue);
                });

            rightBar.transition()
                .attr('y', function(value: any) {
                    return app.height - app.y2(maxright - value.rightValue);
                })
                .attr('height', function(value: any) {
                    return app.y2(maxright - value.rightValue);
                });

            updateAvgs();
            updateLegend();
        }

        function updateAvgs() {
            app.svg.select('.leftavg')
                .attr('y1', function() {
                    return app.y1(app.config.avg.left);
                })
                .attr('y2', function() {
                    return app.y1(app.config.avg.left);
                });

            app.svg.select('.rightavg')
                .attr('y1', function() {
                    return app.y2(app.config.avg.right);
                })
                .attr('y2', function() {
                    return app.y2(app.config.avg.right);
                });
        }

        function updateLegend() {
            app.legend.selectAll('*').remove();

            if (typeof app.config.labels !== 'undefined') {
                app.config.labels.forEach(function(label: any) {
                    app.legend.append('span')
                        .style('background-color', label.color)
                        .style('color', label.color)
                        .style('width', '20px')
                        .style('margin', '5px 5px 5px 30px')
                        .append('text')
                        .text('@');

                    app.legend.append('span')
                        .append('text')
                        .text(label.text);
                });
            }
        }

        function showBarValue(value: any, xPos: any, yPos: any, lineWidth: any) {
            xPos += app.barOffset;
            app.barValue
                .attr('transform', 'translate(' + xPos + ',' + yPos + ')')
                .attr('visibility', 'visible');

            app.barValue.select('line')
                .attr('x2', lineWidth);

            app.barValue.select('text')
                .text(value);
        }

        function hideBarValue() {
            app.barValue
                .attr('visibility', 'hidden');
        }

        if (app.init) {
            setTimeout(() => {
                draw();
                if (app.data) {
                    update();
                }
                app.init = false;
            });
        }
        if (typeof app.data !== 'undefined' && app.data.length) {
            nodata(false);

            if (!app.init) {
                update();
            }
        } else {
            nodata(true);
        }
    };

    /* eslint-enable @typescript-eslint/no-this-alias */

}

import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import * as _echarts from 'echarts';
import { FilterService } from '@proman/services/filter.service';
import { getIndex, getIndexByProperty, omit } from '../../utils';
const echarts: any = _echarts;

declare interface EchartsNodeGraphDataItem {
  id: number;
  name: string;
  x: number;
  y: number;
  category?: number;
  symbolSize?: number;
  value?: number;
}

declare interface EchartsNodeGraphDataLink {
  source: string;
  target: string;
}

export interface EchartsNodeGraphData {
  nodes: EchartsNodeGraphDataItem[];
  links?: EchartsNodeGraphDataLink[];
  categories?: Array<{ name: string }>;
}

export interface EchartsNodeGraphConfig {
  title: string;
  clickCallback?: (params: unknown) => void;
}

@Component({
  selector: 'pro-echart-node-graph',
  template: `
        <div class="EchartNodeGraph" id="nodeGraph" fxFlex #element style="height: 400px;"></div>
    `
})

export class EchartNodeGraphComponent implements OnInit, OnChanges {
  @Input() data: EchartsNodeGraphData;
  @Input() config: EchartsNodeGraphConfig
  @ViewChild('element', { static: true }) element: ElementRef;

  constructor(
    private Filter: FilterService,
  ) {

  }

  ngOnInit() {
    this.draw();
  }

  ngOnChanges() {
    this.draw();
  }

  draw = async () => {
    if (!(this.data && this.config)) return;

    await setTimeout(() => {}, 500);

    this.element.nativeElement.height = '400px';
    let myChart = echarts.init(document.getElementById('nodeGraph'));

    myChart.showLoading();

    console.log('data', this.data);

    let option  = {
      tooltip: {},
      legend: [
        {
          data: (this.data.categories || []).map((a) => a.name)
        }
      ],
      series: [
        {
          // name: this.Filter.translate(this.config.title),
          type: 'graph',
          layout: 'none',
          data: this.data.nodes.map((item) => ({ ...item })),
          links: (this.data.links || []).map((link) => {
            const sourceName = link.source;
            const targetName = link.target;
            const target = getIndexByProperty(this.data.nodes, 'name', targetName);
            const source = getIndexByProperty(this.data.nodes, 'name', sourceName);
            return { target, source };
          }),
          categories: this.data.categories || [],
          roam: true,
          label: {
            show: true,
            position: 'right',
            formatter: '{b}'
          },
          labelLayout: {
            hideOverlap: true
          },
          scaleLimit: {
            min: 0.4,
            max: 2
          },
          lineStyle: {
            color: 'source',
            curveness: 0.3
          }
        }
      ]
    };

    myChart.hideLoading();

    myChart.setOption(option);

    myChart.on('click', (params: any) => {
      console.log(params);
      if (this.config.clickCallback) this.config.clickCallback(params);
    });

  };

}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import moment from 'moment';
import * as _echarts from 'echarts';
import { FilterService } from '@proman/services/filter.service';
const echarts: any = _echarts;

@Component({
    selector: 'pro-echart-dynamic-ordinal-chart',
    template: `
        <div class="EchartDynamicChartOrdinal RightMargin" id="dynamicordinalchart" fxFlex #element style="height: 400px;">

        </div>
    `
})

export class EchartDynamicOrdinalChartComponent implements OnInit {
    @Input() data: any;
    @Input() config: any;
    @ViewChild('element', { static: true }) element: ElementRef;

    constructor( private Filter: FilterService ) {}

    ngOnInit() {
        this.draw();
    }

    ngOnChanges() {
        this.draw();
    }

    draw = async () => {
        if (!(this.data && this.config)) return;

        function getValueProperty(value: any, property: any) {
            let output;
            let array;
            let firstLevel;
            let secondLevel;

            array = property.split('.');

            if (array.length > 1) {
                firstLevel = array[0];
                secondLevel = array[1];

                if (value[firstLevel]) {
                    output = value[firstLevel][secondLevel];
                } else {
                    output = '*';
                }
            } else {
                firstLevel = array[0];

                output = value[firstLevel];
            }

            return output;
        }

        await setTimeout(() => {}, 500);

        this.element.nativeElement.height = '400px';
        let myChart = echarts.init(document.getElementById('dynamicordinalchart'));

        let serie1 = {
            name: this.config.leftAxis.label,
            type: 'bar',
            animation: false,
            areaStyle: {
            },
            lineStyle: {
                width: 1
            },
            barGap: 0,
            data: this.data.map((item: any) => getValueProperty(item, this.config.leftAxis.property)),
            markLine : {
                data : [
                    { type : 'average', name: this.Filter.translate(this.config.leftAxis.label) }
                ]
            },
            // itemStyle: { normal: { color: this.config.colors[0] } },
        };

        let serie2 = {
            name: this.config.rightAxis.label,
            type: 'bar',
            yAxisIndex: 1,
            animation: false,
            areaStyle: {
            },
            lineStyle: {
                width: 1
            },
            data: this.data.map((item: any) => getValueProperty(item, this.config.rightAxis.property)),
            markLine : {
                data : [
                    { type : 'average', name: this.Filter.translate(this.config.rightAxis.label) },
                ]
            },
            // itemStyle: { normal: { color: this.config.colors[1] } },

        };

        let option = {
            grid: {
                left: '16px',
                right: '32px',
                bottom: '32px',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: { title: 'Save as image' }
                }
            },
            tooltip : {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    label: {
                        backgroundColor: '#505765'
                    }
                }
            },
            legend: {
                data: [this.config.leftAxis.label, this.config.rightAxis.label],
                orient: 'horizontal',
                top: 'bottom'
            },
            xAxis : [
                {
                    type : 'category',
                    boundaryGap : 4,
                    axisLine: { onZero: false },
                    data : this.data.map((item: any) => getValueProperty(item, this.config.horizontalAxis.property))
                }
            ],
            yAxis: [
                {
                    name: this.config.leftAxis.label,
                    type: 'value',
                    axisLabel: {
                        rotate: 30
                    },
                },
                {
                    name: this.config.rightAxis.label,
                    type: 'value',
                    axisLabel: {
                        rotate: 30
                    },
                }
            ],
            series: [
                serie1,
                serie2
            ]
        };

// horizontalAxis: {property: "vardaspavard.name", label: "Darbuotojas", show: true}
// leftAxis: {show: true, property: "patikrintaskiekis", label: "Patikrintas kiekis"}
// rightAxis: {show: true, property: "iter", label: "Records number"}

        myChart.setOption(option);

    };

}

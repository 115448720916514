import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { D3TimeLocalisationService } from './services/d3-time-localisation.service';

import { GraphLineComponent } from './components/graph-line.component';
import { GraphOrdinalComponent } from './components/graph-ordinal.component';
import { TimeGraphComponent } from './components/time-graph.component';
import { EchartStackComponent } from './components/echart-stack.component';
import { EchartOrdinalComponent } from './components/echart-ordinal.component';
import { EchartCombComponent } from './components/echart-comb.component';
import { EchartBarComponent } from './components/echart-bar.component';
import { EchartDynamicOrdinalChartComponent } from './components/echart-dynamic-ordinal-chart.component';
import { FlexLayoutModule } from 'ngx-flexible-layout';
import { EchartSankeyComponent } from './components/echart-sankey.component';
import { PipesModule } from '@proman/shared/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { SharedDirectivesModule } from '../shared/directives/shared-directives.module';
import { EchartNodeGraphComponent } from './components/echart-node-graph.component';
import { EchartLineComponent } from "./components/echart-line.component";

const COMPONENTS = [
    TimeGraphComponent,
    GraphLineComponent,
    GraphOrdinalComponent,
    EchartStackComponent,
    EchartSankeyComponent,
    EchartOrdinalComponent,
    EchartCombComponent,
    EchartBarComponent,
    EchartDynamicOrdinalChartComponent,
    EchartNodeGraphComponent,
    EchartLineComponent,
];

@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    RouterModule,
    FlexLayoutModule,
    SharedDirectivesModule,
  ],
    providers: [
        D3TimeLocalisationService,
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS
})
export class PromanChartsModule { }

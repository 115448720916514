import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
let d3: any = window.d3;
import moment from 'moment';
import { FilterService } from '@proman/services/filter.service';
import { D3TimeLocalisationService } from '../services/d3-time-localisation.service';
import { importD3 } from '@proman/utils';

@Component({
    selector: 'pro-graph-line',
    template: `
        <div class="GraphLine" fxFlex #element>
            <div class="chart-holder" style="width: 100%"></div>
            <div class="chart-legend" style="width: 100%"></div>
        </div>
    `
})

export class GraphLineComponent implements OnChanges {
    @Input() data: any;
    @Input() config: any;
    @ViewChild('element', { static: true }) element: ElementRef;
    init: boolean = true;
    _draw: boolean = true;
    x: any;
    y: any;
    line: any;
    xAxis: any;
    yAxis: any;
    constructor(
        private d3TimeLocalization: D3TimeLocalisationService,
        private Filter: FilterService,
    ) {

    }

    ngOnChanges() {
        if (!d3) {
            importD3()
                .then(() => {
                    d3 = window.d3;

                    if (!d3) {
                        console.warn('d3 not loaded');
                    }
                });
        }

        if (this.data) setTimeout(() => this.draw(), 150);
    }

    /* eslint-disable @typescript-eslint/no-this-alias */

    draw() {
        const app = this;

        let element: any = this.element.nativeElement;
        let margin = 50;
        let height = (this.data.height ? this.data.height : 400) - margin * 2;
        let width: any;

        let svg: any;

        let bisectDate: any;

        let initOptimal = true;
        let units = '';

        let root: any;

        let focus: any;

        function parseData() {
            app.data.forEach(function(d: any) {
                d.date = moment(d.date);
                d.value = +d.value;
            });
        }

        // Initial draw of svg
        function draw() {
            app.init = false;
            width = parseInt(d3.select(element).select('.chart-holder').style('width')) - margin * 2;
            svg = d3.select(element).select('.chart-holder').insert('svg', '*');

            bisectDate = d3.bisector(function(d: any) {
                return d.date;
            }).left;

            app.x = d3.time.scale()
                .range([0, width]);

            app.y = d3.scale.linear()
                .range([height, 0]);

            app.xAxis = d3.svg.axis()
                .scale(app.x)
                .orient('bottom')
                .tickFormat(app.d3TimeLocalization.getTickFormat());

            app.yAxis = d3.svg.axis()
                .scale(app.y)
                .orient('left');

            app.line = d3.svg.line()
                .interpolate('basis')
                .x(function(d: any) {
                    return app.x(d.date);
                })
                .y(function(d: any) {
                    return app.y(d.value);
                });

            root = svg
                .attr('width', width + margin * 2)
                .attr('height', height + margin * 2)
                .append('g')
                .attr('transform', 'translate(' + margin + ',' + margin + ')');

            focus = root.append('g')
                .style('display', 'none');

            root.append('text')
                .attr('class', 'nodata')
                .style('display', 'none')
                .attr('x', width / 2)
                .attr('y', height / 2)
                .attr('text-anchor', 'middle')
                .text(app.Filter.translate('data_unavailable'));

            root.append('g')
                .attr('class', 'x axis')
                .attr('transform', 'translate(0,' + height + ')')
                .call(app.xAxis);

            root.append('g')
                .attr('class', 'y axis')
                .call(app.yAxis);

            root.append('path')
                .datum(app.data)
                .attr('class', 'templine')
                .style('fill', 'none')
                .style('stroke', 'steelblue')
                .style('stroke-width', 1.5);

            root.append('text')
                .attr('class', 'current')
                .attr('text-anchor', 'start');

            focus.append('circle')
                .attr('class', 'y')
                .style('fill', 'none')
                .style('stroke', 'red')
                .attr('r', 4);

            focus.append('text')
                .attr('class', 'y1')
                .attr('dx', 8)
                .attr('dy', '-1em');

            focus.append('text')
                .attr('class', 'y2')
                .attr('dx', 8);

            root.append('rect')
                .attr('width', width)
                .attr('height', height)
                .style('fill', 'none')
                .style('pointer-events', 'all')
                .on('mouseover', function() {
                    focus.style('display', null);
                })
                .on('mouseout', function() {
                    focus.style('display', 'none');
                })
                .on('mousemove', mousemove);

            app._draw = true;
            update();
        }

        // Update svg data
        function update() {
            let newroot;
            let currentValue;

            nodata(false);

            if (app._draw) {
                app.x.domain(d3.extent(app.data, function(d: any) {
                    return d.date;
                }));
                app.y.domain([d3.min(app.data, function(d: any) {
                    return d.value;
                }) * 0.9, d3.max(app.data, function(d: any) {
                    return d.value;
                }) * 1.1]);

                newroot = d3.select(element).transition();

                currentValue = app.data[app.data.length - 1].value;

                newroot.select('.templine')
                    .duration(750)
                    .attr('d', app.line(app.data));
                newroot.select('.x.axis')
                    .duration(750)
                    .call(app.xAxis);
                newroot.select('.y.axis')
                    .duration(750)
                    .call(app.yAxis);
                newroot.select('.current')
                    .attr('x', width + 6)
                    .attr('y', app.y(currentValue) + 4)
                    .text(currentValue.toFixed(2) + units);
            }

            setUnits();

            setOptimal();
        }

        function setOptimal() {
            let newroot;

            if (typeof app.config.optimal !== 'undefined' && app.config.optimal && app.config.optimal > app.y.domain()[0] && app.config.optimal < app.y.domain()[1]) {

                if (initOptimal) {
                    root.append('line')
                        .attr('class', 'optimal')
                        .style('stroke', 'green')
                        .style('stroke-width:', 1.5);

                    root.append('text')
                        .attr('class', 'optimal')
                        .style('fill', 'green');

                    initOptimal = false;
                }

                newroot = d3.select(element).transition();

                newroot.select('line.optimal')
                    .attr('x1', 0)
                    .attr('y1', function() {
                        return app.y(app.config.optimal);
                    })
                    .attr('x2', width)
                    .attr('y2', function() {
                        return app.y(app.config.optimal);
                    });

                newroot.select('text.optimal')
                    .attr('dx', 8)
                    .attr('dy', function() {
                        return app.y(app.config.optimal) - 5;
                    })
                    .text(app.Filter.translate('optimal_value') + ': ' + app.config.optimal + units);
            }
        }

        function setUnits() {
            if (typeof app.config.units !== 'undefined') {
                units = app.config.units;

                app.yAxis.tickFormat(function(d: any) {
                    return d + units;
                });
            }
        }

        // Resize svg to fit 100% width
        function resize() {
            width = parseInt(d3.select(element).select('.chart-holder').style('width')) - margin * 2;
            height = parseInt(d3.select(element).select('.chart-holder').style('height')) - margin * 2;

            svg.attr('width', width + margin * 2);

            app.x.range([0, width]);
            app.y.range([height, 0]);

            svg.select('.x.axis')
            // .attr('transform', 'translate(0,' + height + ')')
                .call(app.xAxis);

            svg.select('.y.axis')
                .call(app.yAxis)
                .append('text')
                .attr('fill', '#000')
                .attr('transform', 'rotate(-90)')
                .attr('y', 6)
                .attr('dy', '0.71em')
                .attr('text-anchor', 'end')
                .text('Price ($)');

            svg.select('.templine')
                .attr('d', app.line(app.data));

            svg.select('rect')
                .attr('width', width)
                .attr('height', height);

            svg.select('.current')
                .attr('x', width + 6);

            svg.select('.nodata')
                .attr('x', width / 2);
        }

        // If there is no data
        function nodata(state: any) {
            let svg = d3.select(element);

            svg.selectAll('.templine, .current, .optimal, .x, .y, .y1, .y2')
                .style('display', (state ? 'none' : 'block'));

            svg.select('.nodata')
                .style('display', (!state ? 'none' : 'block'));
        }

        // Show date and value on mouse over the chart
        function mousemove() {
            let x0;
            let i;
            let d0;
            let d1;
            let d;

            if (!app.data.length) {
                return;
            }

            x0 = app.x.invert(d3.mouse(this)[0]);
            i = bisectDate(app.data, x0, 1);
            d0 = app.data[i - 1];
            d1 = app.data[i];
            d = x0 - d0.date > d1.date - x0 ? d1 : d0;

            focus.select('circle.y')
                .attr('transform',
                    'translate(' + app.x(d.date) + ',' +
                    app.y(d.value) + ')');

            focus.select('text.y1')
                .attr('transform',
                    'translate(' + app.x(d.date) + ',' +
                    app.y(d.value) + ')')
                .text(d.value + units);

            focus.select('text.y2')
                .attr('transform',
                    'translate(' + app.x(d.date) + ',' +
                    app.y(d.value) + ')')
                .text(moment(d.date).format('YYYY-MM-DD HH:mm:ss'));
        }

        if (app.data && app.data.length) {
            parseData();
            nodata(false);
            app.init ? draw() : update();
        } else {
            nodata(true);
        }

    }

    /* eslint-enable @typescript-eslint/no-this-alias */

}

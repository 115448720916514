import { Injectable } from '@angular/core';
import { FilterService } from '@proman/services/filter.service';

@Injectable()
export class D3TimeLocalisationService {
    
    constructor(private Filter: FilterService) {

    }
    
    getTickFormat = () => {
        return {};
        // let localeFormatter = d3.locale({
        //     decimal: ',',
        //     thousands: '.',
        //     grouping: [3],
        //     currency: ['€', ''],
        //     dateTime: '%a %b %e %X %Y',
        //     date: '%Y-%m-%d',
        //     time: '%H:%M:%S',
        //     periods: ['AM', 'PM'],
        //     days: this.Filter.translate('_day_names').split(' '),
        //     shortDays: this.Filter.translate('_day_names_short').split(' '),
        //     months: this.Filter.translate('_month_names').split(' '),
        //     shortMonths: this.Filter.translate('_month_names_min').split(' ')
        // });

        // let tickFormat = localeFormatter.timeFormat.multi([
        //     ['%H:%M', function(d: any) {
        //         return d.getMinutes();
        //     }],
        //     ['%H:%M', function(d: any) {
        //         return d.getHours();
        //     }],
        //     ['%a %d', function(d: any) {
        //         return d.getDay() && d.getDate() !== 1;
        //     }],
        //     ['%b %d', function(d: any) {
        //         return d.getDate() !== 1;
        //     }],
        //     ['%B', function(d: any) {
        //         return d.getMonth();
        //     }],
        //     ['%Y', function() {
        //         return true;
        //     }]
        // ]);
        //
        // return tickFormat;
    };
}

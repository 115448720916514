import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { FilterService } from '@proman/services/filter.service';
import moment from 'moment';
import * as echarts from 'echarts';
import { EChartsOption } from 'echarts';

export interface StackChartData {
    key: string;
    values: Array<[number, number]>;
}

@Component({
    selector: 'pro-echart-stack',
    template: `
        <div class="EchartStack RightMargin" id="stack" fxFlex #element style="height: 400px;" (window:resize)="onResize()">

        </div>
    `
})

export class EchartStackComponent implements OnInit, OnChanges {
    @Input() data: StackChartData[];
    @ViewChild('element', { static: true }) element: ElementRef;

    chart: any;

    constructor(
        private Filter: FilterService,
    ) {

    }

    ngOnInit() {

    }

    onResize() {
        // this.element.nativeElement.height = this.height;
        this.element.nativeElement.width = window.innerWidth;
        echarts.init(this.element.nativeElement).resize();

        this.draw();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (!(this.data)) return;

        await setTimeout(() => {}, 500);

        this.element.nativeElement.height = '400px';

        this.draw();
    }

    draw() {
        if (!this.data) return;
        let xAxis = [];
        let legend = [];
        let data: any = [];
        for (let item of this.data) {

            if (item.values.every((data: [number, number]) => data[1] === 0)) continue; // if all values are 0 - skip

            legend.push(item.key);
            data.push({
                name: item.key,
                type: 'line',
                stack: 'A',
                areaStyle: {normal: {}},
                data: item.values.map((item: any) => item[1])
            });
        }

        if (!this.data[0]) return;
        for (let item of this.data[0].values) {
            xAxis.push(moment(item[0]).format('YYYY-MM-DD'))
        }

        let myChart = echarts.init(document.getElementById('stack'));

        // specify chart configuration item and data
        let option: EChartsOption = {
            tooltip : {
                show: true,
                trigger: 'item',
                hideDelay: 1000,
                transitionDuration: 1,
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            legend: {
                data,
                show: (legend.length) < 18
            },
            animation: true,
            toolbox: {
                show: true,
                showTitle: false,
                feature: {
                    saveAsImage: { title: 'Save as image' },
                    magicType: {
                        type: ['line', 'bar'],
                        title: {
                            line: 'Line',
                            bar: 'Bar'
                        },
                    },
                },
                tooltip: {
                    show: false
                }
            },
            grid: {
                left: '16px',
                right: '32px',
                bottom: '72px',
                containLabel: true
            },
            xAxis : [
                {
                    type : 'category',
                    boundaryGap : false,
                    data : xAxis
                }
            ],
            yAxis : [
                {
                    type : 'value'
                }
            ],
            series : data
        };
        // use configuration item and data specified to show chart
        myChart.setOption(option);
    }

}

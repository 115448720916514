import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { FilterService } from '@proman/services/filter.service';
import moment from 'moment';
import * as echarts from 'echarts';
import { EChartsOption } from 'echarts';

export interface LineChartData {
  key: string;
  values: Array<[number, number]>;
}

@Component({
  selector: 'pro-echart-line',
  template: `
        <div class="EchartLine RightMargin" [id]="'line' + dynamicId" fxFlex #element style="height: 400px;" (window:resize)="onResize()">

        </div>
    `
})

export class EchartLineComponent implements OnInit, OnChanges {
  @Input() data: LineChartData[];
  @Input() dynamicId?: string = '';
  @ViewChild('element', { static: true }) element: ElementRef;

  chart: any;

  constructor(
    private Filter: FilterService,
  ) {

  }

  ngOnInit() {

  }

  onResize() {
    // this.element.nativeElement.height = this.height;
    this.element.nativeElement.width = window.innerWidth;
    echarts.init(this.element.nativeElement).resize();

    this.draw();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (!(this.data)) return;

    await setTimeout(() => {}, 500);

    this.element.nativeElement.height = '400px';

    this.draw();
  }

  draw() {
    let xAxis: string[] = [];
    let legend = [];
    let data: any = [];
    for (let item of this.data) {

      if (item.values.every((data: [number, number]) => data[1] === 0)) continue; // if all values are 0 - skip

      legend.push(item.key);
      data.push({
        name: item.key,
        type: 'line',
        data: item.values
      });
    }

    if (!this.data[0]) return;
    this.data.forEach((datum) => {
      for (let item of datum.values) {
        const axisLabel = moment(item[0]).format('YYYY-MM-DD');
        if (!xAxis.includes(axisLabel)) {
          xAxis.push(axisLabel);
        }
      }
    })

    xAxis.sort((a: string, b: string) => moment(a).isBefore(moment(b)) ? -1 : 1);

    let myChart = this.dynamicId ? echarts.init(document.getElementById(`line${this.dynamicId}`)) : echarts.init(document.getElementById('line'));

    // specify chart configuration item and data
    let option: EChartsOption = {
      tooltip : {
        show: true,
        trigger: 'item',
        hideDelay: 1000,
        transitionDuration: 1,
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        }
      },
      legend: {
        data,
        show: (legend.length) < 18
      },
      animation: true,
      grid: {
        left: '16px',
        right: '32px',
        bottom: '72px',
        containLabel: true
      },
      xAxis : [
        {
          type : 'category',
          boundaryGap : false,
          data : xAxis
        }
      ],
      yAxis : [
        {
          type : 'value'
        }
      ],
      series : data
    };
    // use configuration item and data specified to show chart
    myChart.setOption(option);
  }

}

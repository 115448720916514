import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import * as _echarts from 'echarts';
import { FilterService } from '@proman/services/filter.service';
const echarts: any = _echarts;

@Component({
    selector: 'pro-echart-comb',
    template: `
        <div fxLayout="column">
            <div class="EchartComb RightMargin" id="bar" (window:resize)="onResize()" fxLayoutAlign="center center" #element style="height: 400px;"></div>
            <div fxLayout="row" fxLayoutAlign="center center" style="padding: 0 32px;">
            </div>

        </div>
    `
})

export class EchartCombComponent implements OnInit, OnChanges {
    @Input() data: any;
    @Input() config: {
        colors: string[];
        xFormat: string;
        left: { label: string };
        right: { label: string };
    };
    @Input() dataType: string;

    @ViewChild('element', { static: true }) element: ElementRef;
    sumTopLeft: string;
    sumTopRight: string;
    sumDownLeft: string;
    sumDownRight: string;
    height: string = '350px';
    myChart: any;

    constructor(private Filter: FilterService) {
    }

    onResize() {
        this.element.nativeElement.height = this.height;
        this.element.nativeElement.width = window.innerWidth;
        this.myChart = echarts.init(this.element.nativeElement).resize();
    }

    ngOnInit() {
        this.draw();
    }

    ngOnChanges() {
        this.draw();
    }

    draw = async () => {
        if (!(this.data && this.config)) return;

        await setTimeout(() => {}, 500);
        this.element.nativeElement.height = this.height;
        this.myChart = echarts.init(this.element.nativeElement);
        let xAxisValues = this.data.map((item: any) => item.value);

        const sum = (items: number[]) => items.reduce((a, b) => a + b, 0);

        this.sumTopLeft = sum(this.data.map((item: any) => item.leftTopValue)).toFixed(0);
        this.sumTopRight = sum(this.data.map((item: any) => item.rightTopValue)).toFixed(0);
        this.sumDownLeft = sum(this.data.map((item: any) => item.leftDownValue)).toFixed(0);
        this.sumDownRight = sum(this.data.map((item: any) => item.rightDownValue)).toFixed(0);

        let emphasisStyle = {
            itemStyle: {
                barBorderWidth: 1,
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: 'rgba(0,0,0,0.5)'
            }
        };

        let itemStyle = {
            color: new echarts.graphic.LinearGradient(
            0, 0, 0, 1,
                [
                    {offset: 0, color: '#305e93'},
                    {offset: 0.5, color: '#156ab9'},
                    {offset: 1, color: '#1470c2'}
                ]
            )
        };

        let itemStyle2 = {
            color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                    {offset: 0, color: '#4d8d45'},
                    {offset: 0.5, color: '#539d4f'},
                    {offset: 1, color: '#31993c'}
                ]
            )
        };

        let serie1 = {
            name: this.Filter.translate(this.config.left.label),
            type: 'bar',
            yAxisIndex: 0,
            animation: true,
            areaStyle: {
            },
            lineStyle: {
                width: 1
            },
            barGap: '0%',
            data: this.data.map((item: any) => (item.leftTopValue ? item.leftTopValue.toFixed(0) : null)),
            label: {
                normal: {
                    show: this.data.length < 20,
                    position: 'inside'
                }
            },
            itemStyle: itemStyle2,
            emphasis: emphasisStyle
        };

        let serie2 = {
            name: this.Filter.translate(this.config.right.label),
            type: 'bar',
            yAxisIndex: 0,
            animation: true,
            areaStyle: {},
            lineStyle: {
                width: 1
            },
            barGap: '0%',
            data: this.data.map((item: any) => (item.rightTopValue ? item.rightTopValue.toFixed(0) : null)),
            label: {
                normal: {
                    show: this.data.length < 20,
                    position: 'inside'
                }
            },
            itemStyle: itemStyle,
            emphasis: emphasisStyle
        };

        let serie3 = {
            name: this.Filter.translate(this.config.left.label),
            type: 'bar',
            yAxisIndex: 0,
            animation: true,
            areaStyle: {
            },
            lineStyle: {
                width: 0
            },
            barGap: '-100%',
            data: this.data.map((item: any) => (item.leftDownValue ? -item.leftDownValue.toFixed(0) : null)),
            label: {
                normal: {
                    show: this.data.length < 20,
                    position: 'inside'
                }
            },
            itemStyle: itemStyle2,
            emphasis: emphasisStyle
        };

        let serie4 = {
            name: this.Filter.translate(this.config.right.label),
            type: 'bar',
            yAxisIndex: 0,
            animation: true,
            areaStyle: {
            },
            lineStyle: {
                width: 0
            },
            barGap: '-100%',
            data: this.data.map((item: any) => (item.rightDownValue ? -item.rightDownValue.toFixed(0) : null)),
            label: {
                normal: {
                    show: this.data.length < 20,
                    position: 'inside'
                }
            },
            itemStyle: itemStyle,
            emphasis: emphasisStyle,
        };

        let option = {
            grid: {
                left: '32px',
                right: '48px',
                bottom: '32px',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: { title: 'Save as image' }
                }
            },
            tooltip : {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                    label: {
                        backgroundColor: '#505765'
                    }
                }
            },
            legend: {
                data: [this.Filter.translate(this.config.left.label), this.Filter.translate(this.config.right.label)],
                orient: 'horizontal',
                top: 'bottom'
            },
            xAxis : [
                {
                    type : 'category',
                    boundaryGap : 4,
                    axisLine: { onZero: true },
                    data : xAxisValues
                }
            ],
            yAxis: [
                {
                    name: this.Filter.translate(this.config.left.label),
                    type: 'value',
                    axisLabel: {
                        rotate: 30
                    },
                },
                {
                    name: this.Filter.translate(this.config.right.label),
                    type: 'value',
                    axisLabel: {
                        rotate: 30
                    },
                }
            ],
            series: [
                serie1,
                serie2,
                serie3,
                serie4
            ]
        };

        this.myChart.setOption(option);

    };
}

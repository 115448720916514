import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import * as _echarts from 'echarts';
import { FilterService } from '@proman/services/filter.service';
const echarts: any = _echarts;

@Component({
    selector: 'pro-echart-bar',
    template: `
        <div class="EchartBar RightMargin" id="bar" fxFlex #element style="height: 400px;"></div>
    `
})

export class EchartBarComponent implements OnInit, OnChanges {
    @Input() data: any;
    @Input() config: any;
    @ViewChild('element', { static: true }) element: ElementRef;

    constructor(
        private Filter: FilterService,
    ) {

    }

    ngOnInit() {
        this.draw();
    }

    ngOnChanges() {
        this.draw();
    }

    draw = async () => {
        if (!(this.data && this.config)) return;

        await setTimeout(() => {}, 500);

        this.element.nativeElement.height = '400px';
        let myChart = echarts.init(document.getElementById('bar'));

        let xAxisData = this.data.map((item: any) => item.xValue);
        let data = this.data.map((item: any) => ({ value: item.yValue, itemStyle: { color: item.color } }));

        let option = {
           grid: {
               left: '32px',
               right: '32px',
               bottom: '64px',
               containLabel: true
           },
           tooltip: {},
           xAxis: {
               name: this.Filter.translate(this.config.label.x),
               data: xAxisData,
               // silent: false,
               splitLine: { show: false },
               nameLocation: 'middle',
               type: 'category',
               offset: 0,
               nameGap: 25
           },
           yAxis: {
               name: this.Filter.translate(this.config.label.y),
           },
           series: [{
               // name: 'bar',
               type: 'bar',
               data,
               animationDelay(idx: number) {
                   return idx * 10;
               }
           }],
           animationEasing: 'elasticOut',
           animationDelayUpdate(idx: number) {
               return idx * 5;
           }
       };

        myChart.setOption(option);

        myChart.on('click', (params: any) => {
            if (this.config.clickCallback) this.config.clickCallback(params);
        });

    };

}
